.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: var(--z-ui-loader-mask);
  display: flex;
}

.main {
  flex: 1;

  @media (min-width: 926px) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 100%;
  }
}

.left {
  flex: 1;
  background: var(--color-background);
  min-width: 16rem;
  width: 33vw;
  max-width: 26.5rem;
  height: 100%;

  @media (min-width: 926px) {
    max-width: 40vw;
  }

  @media (min-width: 1276px) {
    width: 25vw;
    max-width: 33vw;
  }

  @media (max-width: 1275px) {
    flex: 2;
  }

  @media (max-width: 925px) {
    width: 26.5rem !important;
  }

  @media (max-width: 600px) {
    max-width: none;
    width: 100vw !important;
  }
}

.middle {
  flex: 3;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-size: cover !important;

  @media (max-width: 1275px) {
    border-right: none;
  }

  @media (max-width: 600px) {
    display: none;
  }
}

.right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  width: var(--right-column-width);
  background: var(--color-background);
}

.blank {
  flex: 1;
  background-color: var(--color-background);
}
