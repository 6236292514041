.root {
  --color-fill: var(--color-primary);

  flex-shrink: 0;
  display: flex;
  width: 1rem;
  height: 1rem;
}

.middle {
  width: 1.25rem;
  height: 1.25rem;
}

.big {
  width: 1.5rem;
  height: 1.5rem;
}

.adaptive {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  vertical-align: text-top;
}

.svg {
  width: 100%;
  height: 100%;
}

.clickable {
  cursor: var(--custom-cursor, pointer);
  pointer-events: auto;
}
