#AppInactive {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    max-width: 28rem;
    margin: auto;
    padding: 1.5rem;
    text-align: center;
    background: var(--color-background);
    border-radius: var(--border-radius-default);
  }

  .title {
    margin-top: 1rem;
  }

  .description {
    color: var(--color-text-secondary);
    font-size: 0.875rem;
  }

  img {
    width: 100%;
    max-width: 20rem;
  }

  .Button {
    margin-top: 1rem;
  }
}
