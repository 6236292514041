.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}

.icon {
  position: absolute;
  width: 100%;
  height: 100%;
}

.title {
  z-index: 1;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  :global(.emoji) {
    width: 1em;
    height: 1em;
  }
}
