.link {
  color: inherit;

  &:hover {
    color: inherit;

    &:global(.GroupCallLink) {
      text-decoration: none;
    }
  }
}

.isPrimary {
  color: var(--color-primary);

  &:hover {
    color: var(--color-primary);
  }
}
